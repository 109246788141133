import { Col, Row, Table } from "antd";
import { Channel, GetOrderQuery, ProductVariant } from "generated/graphql";
import React from "react";
import { Price } from "utils/price";
import { Label, Wrapper } from "./style";

interface Props {
  order?: GetOrderQuery["order"];
  channel?: Channel;
}

const InvoiceDocument = React.forwardRef<any, Props>(
  ({ order, channel }, ref) => {
    const columns = [
      {
        title: "Product",
        dataIndex: "productVariant",
        render: (variant: ProductVariant) =>
          `${variant.product.name} ${variant.name}`,
      },
      {
        title: "Price",
        dataIndex: "unitPrice",
        render: (unitPrice: number) => `$${(unitPrice / 100).toFixed(2)}`,
      },
      {
        title: "Qty",
        dataIndex: "quantity",
      },
      {
        title: "Total",
        dataIndex: "linePrice",
        render: (linePrice: number) => `$${(linePrice / 100).toFixed(2)}`,
      },
    ];

    if (!order) return null;

    return (
      <div style={{ padding: 20, width: "100%" }} ref={ref}>
        <Row>
          <Col span={10}>
            <Wrapper>
              <h3>
                From: {channel?.customFields?.firstName}{" "}
                {channel?.customFields?.lastName}
              </h3>
              <div>{channel?.customFields?.address}</div>
              <div>
                {channel?.customFields?.city}, {channel?.customFields?.state}
              </div>
              <div>{channel?.customFields?.email}</div>
            </Wrapper>
          </Col>
          <Col span={12} offset={2}>
            <table
              style={{
                marginLeft: "auto",
              }}
            >
              <tbody>
                <tr>
                  <Label>Invoice :</Label>
                  <td>{order?.code}</td>
                </tr>
                <tr>
                  <Label>Invoice Date Time :</Label>
                  <td>
                    {order?.createdAt &&
                      new Date(order?.createdAt).toLocaleString()}
                  </td>
                </tr>
                {/*
                <tr>
                  <Label>Due Date Time:</Label>
                  <td>
                    {order?.createdAt &&
                      new Date(order?.createdAt).toLocaleString()}
                  </td>
                </tr>
                */}
              </tbody>
            </table>
          </Col>
        </Row>

        <Row style={{ marginTop: 48 }}>
          <Col span={10}>
            <Wrapper>
              <h3> To: {order?.billingAddress?.fullName}</h3>
              <div>{order?.billingAddress?.streetLine1}</div>
              <div>{order?.billingAddress?.streetLine2}</div>
              <div>
                {order?.billingAddress?.city}, {order?.billingAddress?.province}
                &nbsp;
                {order?.billingAddress?.postalCode}
              </div>
              <div>{order?.billingAddress?.customFields?.email}</div>
            </Wrapper>
          </Col>
        </Row>

        <Row
          style={{ marginTop: 48, display: "flex", flexDirection: "column" }}
        >
          <Table
            dataSource={order?.lines.map((obj: any) => ({
              ...obj,
              key: obj.id,
            }))}
            pagination={false}
            columns={columns}
          ></Table>
        </Row>

        <Row style={{ marginTop: 48 }}>
          <Col span={8} offset={16}>
            <table>
              <tbody>
                <tr>
                  <Label>Sub Total : </Label>
                  <td>${(order?.subTotal / 100).toFixed(2)}</td>
                </tr>

                <tr>
                  <Label>Shipping Rate : </Label>
                  <td>${(order?.shipping / 100).toFixed(2)}</td>
                </tr>

                <tr>
                  <Label>TAX :</Label>
                  <td>
                    ${((order?.totalWithTax - order?.total) / 100).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <Label>Grand Total :</Label>
                  <td>{`$${(order?.totalWithTax / 100).toFixed(2)}`}</td>
                </tr>
                <tr>
                  <Label>Total Discounts Applied :</Label>
                  <td>
                    <Price
                      priceWithTax={order?.discounts?.reduce(
                        (total, discount) => total + discount.amount,
                        0
                      )}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>

        <div
          style={{
            marginTop: 48,
          }}
        ></div>
        {order?.customFields?.specialInstructions && (
          <div>
            <b>Special Instructions :</b>{" "}
            {order?.customFields?.specialInstructions}
          </div>
        )}
        {order?.customFields?.giftMessage && (
          <div>
            <b>Gift Message :</b> {order?.customFields?.giftMessage}
          </div>
        )}
      </div>
    );
  }
);

export default InvoiceDocument;
