import { AutoComplete, Col, Input, message, Row, Tag } from "antd";
import TwfButton from "component/button";
import { SelectTitle } from "component/header";
import {
  useGetProductVariantLazyQuery,
  useProductSelectorSearchLazyQuery,
} from "generated/graphql";
import { FunctionComponent, useEffect, useState } from "react";
import { OperationCard, OperationProps, VariantOption } from "../conditions";

const ContainsProductsCondition: FunctionComponent<OperationProps> = ({
  code,
  configArgs,
  onConfigArgChange,
  onRemove,
}) => {
  const [productSelectorSearchQuery, { data: productResult }] =
    useProductSelectorSearchLazyQuery();
  const [getProductVariantQuery] = useGetProductVariantLazyQuery({
    fetchPolicy: "network-only",
  });

  const [productVariantValue, setProductVariantValue] = useState<string>("");

  const [selectedProductVariants, setSelectedProductVariants] = useState<
    VariantOption[]
  >([]);

  const minimum = configArgs.find((arg) => arg.name === "minimum")?.value;

  useEffect(() => {
    async function getSavedVariants() {
      if (selectedProductVariants.length > 0) return;

      const productVariantIds = configArgs.find(
        (arg) => arg.name === "productVariantIds"
      )?.value;

      if (productVariantIds) {
        let variantIds: string[] = JSON.parse(productVariantIds);

        const uniqueIds = [...new Set(variantIds)];

        const savedVariants: any[] = [];

        for (const id of uniqueIds) {
          const result = await getProductVariantQuery({
            variables: { id },
            fetchPolicy: "network-only",
          });

          savedVariants.push(result);
        }

        setSelectedProductVariants(
          savedVariants.map((variant) => ({
            label: `${variant?.data?.productVariant?.product.name} ${
              variant?.data?.productVariant!.name
            }`,
            value: variant?.data?.productVariant!.id!,
          }))
        );
      }
    }

    getSavedVariants();
  }, [configArgs, getProductVariantQuery, selectedProductVariants.length]);

  const productVariantSearchHandler = (value: string) => {
    productSelectorSearch(value);
    setProductVariantValue(value);
  };

  const productSelectorSearch = (term: string) => {
    productSelectorSearchQuery({ variables: { term, take: 10 } });
  };

  const addSelectedVariant = (value: string, item: any) => {
    if (
      selectedProductVariants.find((variant) => variant.value === item.value)
    ) {
      message.error("This variant is already selected");
      return;
    }
    setProductVariantValue("");
    setNewSelectedItems([...selectedProductVariants, item]);
  };

  const removeSelectedVariant = (id: string) => {
    setNewSelectedItems([
      ...selectedProductVariants.filter(
        (variant: VariantOption) => variant.value !== id
      ),
    ]);
  };

  const setNewSelectedItems = (items: VariantOption[]) => {
    setSelectedProductVariants(items);

    onConfigArgChange(
      {
        name: "productVariantIds",
        value: JSON.stringify(items.map((item) => item.value)),
      },
      code
    );
  };

  return (
    <OperationCard
      title={`Buy at least ${minimum} of the specified products `}
      style={{ marginTop: 16 }}
      actions={[
        <TwfButton key="remove" type="link" onClick={() => onRemove(code)}>
          Remove
        </TwfButton>,
      ]}
    >
      <Row>
        <Col span={24}>
          Minimum:{" "}
          <Input
            type="number"
            value={minimum}
            onChange={(e: any) =>
              onConfigArgChange(
                { name: "minimum", value: e.target.value },
                code
              )
            }
            style={{ width: 160 }}
          />
        </Col>
        <Col span={24} style={{ paddingTop: 16 }}>
          {selectedProductVariants.map((variant: VariantOption) => (
            <Tag
              key={variant.value}
              closable
              onClose={() => removeSelectedVariant(variant.value)}
              style={{
                whiteSpace: "pre-wrap",
                height: "auto",
              }}
            >
              {variant.label}
            </Tag>
          ))}
        </Col>
        <Col span={24} style={{ paddingTop: 16 }}>
          <SelectTitle>Product variants</SelectTitle>
          <AutoComplete
            options={
              productResult?.search.items.map((c) => ({
                label: `${c.productName} ${c.productVariantName}`,
                value: c.productVariantId,
              })) ?? []
            }
            onSelect={addSelectedVariant}
            style={{ minWidth: 200, width: "100%" }}
            onSearch={productVariantSearchHandler}
            placeholder="Search by product name or SKU"
            value={productVariantValue}
          ></AutoComplete>
        </Col>
      </Row>
    </OperationCard>
  );
};

export default ContainsProductsCondition;
